export let config = {
	NODE_ENV: process.env.NODE_ENV,
	API_BASE_URL: "/api/",
	DISPLAY_STAGING_WARNING: true,
	SENTRY_DSN: "https://b91bb6380f4147738efba9fe08f1c43e@sentry.io/1538569",
	SENTRY_RELEASE: `gamesheet-dashboard@${process.env.COMMIT_SHA}`,
	SMARTLOOK_KEY: "502979a49cc0f8b5986e6315ec7ebbfc846d2de9",
	REGISTRY_SYNC_URL: "",
	SSO_REGISTRY_URL: "",
	MAINTENANCE_START_DATE: new Date(2018, 11, 25),
	MAINTENANCE_FINISH_DATE: new Date(2018, 11, 26),
	MAINTENANCE_LOCK: false,
	features: {},
	BFF_API: "",
	V4E_GAMES_URL: "",
	SCORESHEET_EXPORT_SERVICE_URL: "",
	TEAM_DASHBOARD: "",
	AUTH_GATEWAY: "",
	STATS_WIDGET: "",
	EVENTLOG_GATEWAY: "",
	V5_ADMIN_DASHBOARD_URL: "",
};

switch (process.env.REACT_APP_ENV) {
	case "beta": {
		config = {
			...config,
			NODE_ENV: "production",
			DISPLAY_STAGING_WARNING: false,
			SENTRY_ENVIRONMENT: "production",
			V4_URL: "api.gamesheet.app",
			REGISTRY_SYNC_URL: "gamesheet-production.web.app",
			SSO_REGISTRY_URL: "gs-dashboard-beta.web.app",
			V4E_GAMES_URL: "https://gs-prod-private-games-api.web.app",
			BFF_API: "https://bff-dashboard-api-awy26srzoa-nn.a.run.app",
			SCORESHEET_EXPORT_SERVICE_URL:
				"https://scoresheet-service-awy26srzoa-nn.a.run.app",
			TEAM_DASHBOARD: "https://beta.teams.gamesheet.app",
			AUTH_GATEWAY: "https://gateway-authserver-awy26srzoa-nn.a.run.app",
			STATS_WIDGET: "https://beta.gamesheetstats.com",
			EVENTLOG_GATEWAY: "https://gateway-eventlog-awy26srzoa-nn.a.run.app",
			V5_ADMIN_DASHBOARD_URL: "https://develop.admin-dashboard-v5.pages.dev",
			FIREBASE_CONFIG: {
				apiKey: "AIzaSyCk5pKBFxvCMuwPchzXgvvz4XmmscJTvs8",
				authDomain: "gamesheet-production.firebaseapp.com",
				databaseURL: "https://gamesheet-production.firebaseio.com",
				projectId: "gamesheet-production",
				storageBucket: "gamesheet-production.appspot.com",
				messagingSenderId: "978308121196",
				appId: "1:978308121196:web:3f209999b85e8f6565ce49",
				measurementId: "G-3022CSCXJQ",
			},
			UNLEASH_CONFIG: {
				url: "https://features.gamesheet.io/api/frontend",
				clientKey:
					"*:production.5b0b57534201f2aa2d51f1632f39eed20c2b347ef8e4632ac060284b",
				appName: "default",
				environment: "production",
				refreshInterval: 15,
			},
		};

		break;
	}

	case "prod": {
		config = {
			...config,
			NODE_ENV: "production",
			DISPLAY_STAGING_WARNING: false,
			SENTRY_ENVIRONMENT: "production",
			V4_URL: "api.gamesheet.app",
			REGISTRY_SYNC_URL: "gamesheet-production.web.app",
			SSO_REGISTRY_URL: "gs-dashboard-prod.web.app",
			V4E_GAMES_URL: "https://gs-prod-private-games-api.web.app",
			BFF_API: "https://bff-dashboard-api-awy26srzoa-nn.a.run.app",
			SCORESHEET_EXPORT_SERVICE_URL:
				"https://scoresheet-service-awy26srzoa-nn.a.run.app",
			TEAM_DASHBOARD: "https://teams.gamesheet.app",
			AUTH_GATEWAY: "https://gateway-authserver-awy26srzoa-nn.a.run.app",
			STATS_WIDGET: "https://gamesheetstats.com",
			EVENTLOG_GATEWAY: "https://gateway-eventlog-awy26srzoa-nn.a.run.app",
			V5_ADMIN_DASHBOARD_URL: "https://admin.gamesheet.app",
			FIREBASE_CONFIG: {
				apiKey: "AIzaSyCk5pKBFxvCMuwPchzXgvvz4XmmscJTvs8",
				authDomain: "gamesheet-production.firebaseapp.com",
				databaseURL: "https://gamesheet-production.firebaseio.com",
				projectId: "gamesheet-production",
				storageBucket: "gamesheet-production.appspot.com",
				messagingSenderId: "978308121196",
				appId: "1:978308121196:web:3f209999b85e8f6565ce49",
				measurementId: "G-3022CSCXJQ",
			},
			UNLEASH_CONFIG: {
				url: "https://features.gamesheet.io/api/frontend",
				clientKey:
					"*:production.5b0b57534201f2aa2d51f1632f39eed20c2b347ef8e4632ac060284b",
				appName: "default",
				environment: "production",
				refreshInterval: 15,
			},
		};

		break;
	}

	case "qa": {
		console.log("using QA config");

		config = {
			...config,
			NODE_ENV: "qa",
			SENTRY_ENVIRONMENT: "development",
			V4_URL: "api-qa.gamesheet.app",
			REGISTRY_SYNC_URL: "gamesheet-v4-testing-stack.web.app",
			SSO_REGISTRY_URL: "gs-dashboard-qa.web.app",
			V4E_GAMES_URL: "https://gs-qa-private-games-api.web.app",
			BFF_API: "https://bff-dashboard-api-5weo5hsb7q-nn.a.run.app",
			SCORESHEET_EXPORT_SERVICE_URL:
				"https://scoresheet-service-5weo5hsb7q-nn.a.run.app",
			TEAM_DASHBOARD: "https://qa1.teams.gamesheet.app",
			AUTH_GATEWAY: "https://gateway-authserver-5weo5hsb7q-nn.a.run.app",
			STATS_WIDGET: "https://qa.gamesheetstats.com",
			EVENTLOG_GATEWAY: "https://gateway-eventlog-5weo5hsb7q-nn.a.run.app",
			V5_ADMIN_DASHBOARD_URL: "", //TODO what will QA admin dashboard URL be?
			FIREBASE_CONFIG: {
				apiKey: "AIzaSyBiCavmlIowAvuyxIlJ6DeEHoFvGOuZkuE",
				authDomain: "gamesheet-v4-testing-stack.firebaseapp.com",
				projectId: "gamesheet-v4-testing-stack",
				storageBucket: "gamesheet-v4-testing-stack.appspot.com",
				messagingSenderId: "358351777959",
				appId: "1:358351777959:web:c47407258e800189036b82",
				measurementId: "G-YRBNVRQ8Q3",
			},
			UNLEASH_CONFIG: {
				url: "https://features.gamesheet.io/api/frontend",
				clientKey:
					"*:development.666e2453b6243674b882d5f1078884c717cb28879cd93b4ce6d9fb3d",
				appName: "default",
				instanceId: null,
				environment: "development",
				refreshInterval: 15,
			},
		};

		break;
	}

	default:
	case "legacy": {
		console.log("using LEGACY config");

		config = {
			...config,
			NODE_ENV: "legacy",
			API_BASE_URL:
				process.env.NODE_ENV === "test" ? "http://www.example.com/" : "/api/",
			DISPLAY_STAGING_WARNING: true,
			SENTRY_DSN:
				process.env.SENTRY_DSN && process.env.SENTRY_DSN !== ""
					? process.env.SENTRY_DSN
					: undefined,
			SENTRY_ENVIRONMENT:
				process.env.SENTRY_ENVIRONMENT && process.env.SENTRY_ENVIRONMENT !== ""
					? process.env.SENTRY_ENVIRONMENT
					: "development",
			SENTRY_RELEASE:
				process.env.SENTRY_RELEASE && process.env.SENTRY_RELEASE !== ""
					? process.env.SENTRY_RELEASE
					: undefined,
			SMARTLOOK_KEY: "502979a49cc0f8b5986e6315ec7ebbfc846d2de9",
			V4_URL: process.env.V4_URL || "api-dev.gamesheet.app",
			V5_ADMIN_DASHBOARD_URL: "https://develop.admin-dashboard-v5.pages.dev",
			REGISTRY_SYNC_URL:
				process.env.V4_URL || "gamesheet-v4-development-stack.web.app",
			FIREBASE_CONFIG: (process.env.REACT_APP_FIREBASE_CONFIG &&
				JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)) || {
				apiKey: "AIzaSyAev_UVG0mYihi9fGgJ0spoAaWlClgl1T8",
				authDomain: "gamesheet-v4-development-stack.firebaseapp.com",
				projectId: "gamesheet-v4-development-stack",
				storageBucket: "gamesheet-v4-development-stack.appspot.com",
				messagingSenderId: "773167879850",
				appId: "1:773167879850:web:4efeb307b62f4bc2bb22d3",
				measurementId: "G-1EB5X08GN3",
			},
			UNLEASH_CONFIG: {
				url: "https://features.gamesheet.io/api/frontend",
				clientKey:
					"*:development.666e2453b6243674b882d5f1078884c717cb28879cd93b4ce6d9fb3d",
				appName: "default",
				environment: "development",
				refreshInterval: 15,
			},
		};
		break;
	}

	case "dev": {
		console.log("using DEV config");

		config = {
			...config,
			NODE_ENV: "development",
			SENTRY_ENVIRONMENT: "development",
			V4_URL: "api-dev.gamesheet.app",
			REGISTRY_SYNC_URL: "gamesheet-v4-development-stack.web.app",
			SSO_REGISTRY_URL: "gs-dashboard-dev.web.app",
			V4E_GAMES_URL: "https://gs-dev-private-games-api.web.app",
			BFF_API: "https://bff-dashboard-api-rxj26aywsq-nn.a.run.app",
			SCORESHEET_EXPORT_SERVICE_URL:
				"https://scoresheet-service-rxj26aywsq-nn.a.run.app",
			TEAM_DASHBOARD: "https://dev.team-manager-dashboard.pages.dev",
			AUTH_GATEWAY: "https://gateway-authserver-rxj26aywsq-nn.a.run.app",
			STATS_WIDGET: "https://dev.gamesheetstats.com",
			EVENTLOG_GATEWAY: "https://gateway-eventlog-rxj26aywsq-nn.a.run.app",
			V5_ADMIN_DASHBOARD_URL: "https://develop.admin-dashboard-v5.pages.dev",
			FIREBASE_CONFIG: {
				apiKey: "AIzaSyAev_UVG0mYihi9fGgJ0spoAaWlClgl1T8",
				authDomain: "gamesheet-v4-development-stack.firebaseapp.com",
				projectId: "gamesheet-v4-development-stack",
				storageBucket: "gamesheet-v4-development-stack.appspot.com",
				messagingSenderId: "773167879850",
				appId: "1:773167879850:web:4efeb307b62f4bc2bb22d3",
				measurementId: "G-1EB5X08GN3",
			},
			UNLEASH_CONFIG: {
				url: "https://features.gamesheet.io/api/frontend",
				clientKey:
					"*:development.666e2453b6243674b882d5f1078884c717cb28879cd93b4ce6d9fb3d",
				appName: "default",
				environment: "development",
				refreshInterval: 15,
			},
		};

		break;
	}

	case "local": {
		console.log("using DEV config");

		config = {
			...config,
			NODE_ENV: "development",
			SENTRY_ENVIRONMENT: "development",
			V4_URL: "api-dev.gamesheet.app",
			REGISTRY_SYNC_URL: "gamesheet-v4-development-stack.web.app",
			SSO_REGISTRY_URL: "gs-dashboard-dev.web.app",
			V4E_GAMES_URL: "https://gs-dev-private-games-api.web.app",
			BFF_API: "http://localhost:3000",
			SCORESHEET_EXPORT_SERVICE_URL:
				"https://scoresheet-service-rxj26aywsq-nn.a.run.app",
			TEAM_DASHBOARD: "https://dev.team-manager-dashboard.pages.dev",
			AUTH_GATEWAY: "https://gateway-authserver-rxj26aywsq-nn.a.run.app",
			STATS_WIDGET: "https://dev.gamesheetstats.com",
			EVENTLOG_GATEWAY: "https://gateway-eventlog-rxj26aywsq-nn.a.run.app",
			V5_ADMIN_DASHBOARD_URL: "https://develop.admin-dashboard-v5.pages.dev",
			FIREBASE_CONFIG: {
				apiKey: "AIzaSyAev_UVG0mYihi9fGgJ0spoAaWlClgl1T8",
				authDomain: "gamesheet-v4-development-stack.firebaseapp.com",
				projectId: "gamesheet-v4-development-stack",
				storageBucket: "gamesheet-v4-development-stack.appspot.com",
				messagingSenderId: "773167879850",
				appId: "1:773167879850:web:4efeb307b62f4bc2bb22d3",
				measurementId: "G-1EB5X08GN3",
			},
			UNLEASH_CONFIG: {
				url: "https://features.gamesheet.io/api/frontend",
				clientKey:
					"*:development.666e2453b6243674b882d5f1078884c717cb28879cd93b4ce6d9fb3d",
				appName: "default",
				environment: "development",
				refreshInterval: 15,
			},
		};

		break;
	}
}
